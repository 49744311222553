<template>
  <Dialog v-if='visible' v-model='visible' :options='dialogOptions.dialog'>
    <v-form
      v-if='Object.keys(info).length'
      ref='form'
      style='display: flex; align-items: center; position: relative'
    >
      <v-row style='flex: 1; margin: 0'>
        <template v-for='e of panel'>
          <v-col
            v-if='e.visible ? e.visible(info, isSubbranch) : true'
            :key='e.field'
            cols='12'
          >
            <span>{{ e.text }}：</span>
            <span :style='e.style ? e.style(info) : {}' class='breakAll'>{{
                e.format
                  ? e.format(info[e.field])
                  : info[e.field]
                  ? info[e.field]
                  : '-'
              }}</span>
          </v-col>
        </template>
        <template v-if='!isSubbranch && (isUrge || isPunish)'>
          <v-col v-if='isUrge' cols='6'>
            <span>处理建议：</span>
            <v-text-field
              v-model='form.warnRemark'
              :counter='200'
              :rules="[
                (v) => !!v || '请输入处理建议！',
                (v) => (v && v.length <= 200) || '最大输入200字符！',
              ]"
              placeholder='请输入处理建议'
              solo
              style='margin-top: 8px'
            >
            </v-text-field>
          </v-col>
          <v-col v-if='isPunish' cols='6'>
            <span>处罚登记：</span>
            <v-text-field
              v-model='form.warnRemark'
              :counter='200'
              :rules="[
                (v) => !!v || '请输入处罚登记！',
                (v) => (v && v.length <= 200) || '最大输入200字符！',
              ]"
              placeholder='请输入处罚登记'
              solo
              style='margin-top: 8px;'
            >
            </v-text-field>
          </v-col>
          <v-col cols='12' style='cursor: pointer'>
            <div class='btn-box' @click='onSubmit'>
              {{ isUrge ? '催促' : '处罚登记' }}
            </div>
          </v-col>
        </template>
      </v-row>
<!--      <div-->
<!--        style='-->
<!--          width: 960px;-->
<!--          height: 540px;-->
<!--          display: flex;-->
<!--          align-items: center;-->
<!--          justify-content: center;-->
<!--          overflow: hidden;-->
<!--        '-->
<!--      >-->
<!--        <span-->
<!--          v-if='!info.attach || isImage(info.attach)'-->
<!--          v-lazy='`${$imgUrl}${info.attach}`'-->
<!--          alt='违规'-->
<!--          style='height: 100%; flex: 1; object-fit: contain; overflow: hidden'-->
<!--        ></span>-->
<!--        <video-->
<!--          v-else-->
<!--          :src='`${$imgUrl}${info.attach}`'-->
<!--          controls-->
<!--          style='flex: 1; object-fit: contain; overflow: hidden'-->
<!--        ></video>-->
<!--      </div>-->
      <v-overlay :value='overlay' absolute>
        <v-progress-circular indeterminate size='64'></v-progress-circular>
        <div>数据提交中...</div>
      </v-overlay>
    </v-form>
  </Dialog>
</template>

<script>
import { mapState } from 'vuex';
import exception from '@/utils/exception';
import { assiginDeep, isImage } from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';

export default {
  //组件注册
  components: {
    Dialog,
  },
  //组件传值
  props: {},
  data() {
    let that = this;
    return {
      form: {
        remark: undefined,
      },
      overlay: false,
      panel: [
        {
          text: '机构名称',
          field: 'organizationText',
        },
        {
          text: '任务编号',
          field: 'taskNo',
        },
        {
          text: '任务名称',
          field: 'taskName',
        },
        {
          text: '任务类型',
          field: 'analysisItemText',
        },
        {
          text: '生成日期',
          field: 'planCreateTime'
        },
        {
          text: '计划完成时间',
          field: 'planCompleteTime',
        },
        {
          text: '已催促次数',
          field: 'urgeTimes',
          format: (value) => `第${value ? value : 0}次`,
          visible: (info, isSubbranch) =>
            ['01', '02', '05'].includes(info.status) && !isSubbranch,
        },
        {
          text: '执行状态',
          field: 'statusText',
          // visible: (info, isSubbranch) => isSubbranch,
        },
        {
          text: '处罚登记',
          field: 'warnRemark',
          visible: () => !that.isPunish,
        },
      ],
      info: {},
      visible: false,
      dialogOptions: {
        dialog: {
          width: 850,
          title: '任务详情',
        },
      },
    };
  },
  //计算属性
  computed: {
    ...mapState({
      isSubbranch: (state) => state.screenStore.isSubbranch,
    }),
    isUrge() {
      return ['01', '02', '05'].includes(this.info.status);
    },
    isPunish() {
      return ['04'].includes(this.info.status) && this.info.punishFlag == '02';
    },
  },
  //属性监听
  watch: {
    visible() {
      if (this.visible) {
        this.info = {};
        this.form = { remark: undefined };
        this.overlay = false;
      }
    },
  },
  //DOM访问
  mounted() {
  },
  //保存方法
  methods: {
    isImage,
    openDialog(options = {}) {
      console.log(options.row);
      this.dialogOptions = assiginDeep(this.dialogOptions, { ...options });
      this.visible = true;
      this.$nextTick(() => {
        if (options.row) {
          this.form.taskUUID = options.row.uuid;
          this.info = options.row;
        }
      });
    },
    async onSubmit() {
      try {
        if (this.$refs.form && !this.$refs.form.validate()) return;
        this.overlay = true;
        const data = await this.$http.post(
          `/${this.$businessISG}/monitor/home/task-${
            this.isUrge ? 'urge' : this.isPunish ? 'punish' : null
          }`,
          this.form,
          {
            'Content-Type': 'application/json;charset=UTF-8',
          },
        );
        if (data.code != 200) throw data.message;
        this.overlay = false;
        exception.toastInfo(data.message);
        this.visible = false;
        this.$emit('success');
      } catch (error) {
        exception.toastError(error + '');
        this.overlay = false;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.btn-box {
  width: 90px;
  height: 40px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #25e3ff;
  border: 1px solid #2cadff;
  box-shadow: #387992 0px -1px 20px 0px inset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.col {
  font-size: 16px;
  font-weight: 400;
  color: #d2e2e7;
}

.breakAll {
  word-break: break-all;
}
</style>
